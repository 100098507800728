<template>
    <LoadingScreen v-if="isLoadingPage" />
    <div v-else class="screen-number container-screen">
        <div @click="onClickBack" class="loto__back">
            <img src="~ThemePath/images/chevron-left-green.png" />
            <div class="loto__back__title">
                {{ getTranslatedText('back') }}
            </div>
        </div>
        <div class="loto__title">{{ getTranslatedText(INSTANT_GAME_TITLE) }}</div>
        <template v-if="!isEmpty">
            <div class="screen-number__box-btn-quick-pick">
                <ButtonCommon
                    :isNoHover="true"
                    value="Quick Pick"
                    className="screen-number__btn-quick-pick"
                    @on-click-button-common="onClickQuickPick()"
                />
            </div>
            <div class="screen-number__list">
                <div
                    class="screen-number__item"
                    :class="{ active: selection.includes(value.number) }"
                    v-for="(value, index) in listNumber"
                    :key="index"
                    @click="() => onSelectNumber(value.number)"
                >
                    {{ value.number }}
                </div>
            </div>
            <div class="screen-number__value-number">
                <input
                    autocomplete="off"
                    @keyup="removeDotDash"
                    v-model="valueSelection"
                    class="screen-number__input"
                    type="text"
                    @blur="onBlurValueSelection"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    ref="inputValueNumber"
                    @keyup.enter="hideKeypad('inputValueNumber')"
                />
            </div>
            <div class="screen-number__footer">
                <div class="screen-number__footer-action-price">
                    <div class="screen-number__info">
                        <span class="screen-number__text bold">{{ getTranslatedText(INSTANT_GAME_TITLE) }}</span>
                        <span class="screen-number__text">{{ wager }}</span>
                    </div>
                    <input
                        disabled="disabled"
                        v-model="wager"
                        class="screen-number__input price"
                        step="1"
                        min="0"
                        max="100"
                        pattern="[0-9]*"
                        inputmode="numeric"
                    />
                </div>
                <div class="screen-number__footer-action">
                    <div class="screen-number__info">
                        <span class="screen-number__text bold">{{ getTranslatedText('total') }}</span>
                        <span class="screen-number__text bold">{{ wager }}</span>
                    </div>
                    <div class="screen-number__box-button">
                        <ButtonCommon
                            :isNoHover="true"
                            :isLoading="$store.state.isLoadingAddCart"
                            value="clear"
                            @on-click-button-common="onShowConfirmClear()"
                        />
                        <ButtonCommon
                            :isNoHover="true"
                            :isLoading="$store.state.isLoadingAddCart"
                            type="error"
                            value="add to cart"
                            @on-click-button-common="onAddToCart()"
                        />
                    </div>
                </div>
            </div>
        </template>
        <div class="screen-number__empty" v-else>{{ getTranslatedText('empty game') }}</div>
    </div>
</template>

<script>
import EventBus from '@/utils/EventBus'
import gamesService from '@/services/games.service'
import languageMixins from '@/mixins/language/language-mixins'
import ButtonCommon from '@/components/common/button/index.vue'
import LoadingScreen from '@/components/common/loading/index.vue'
import TitleCommon from '@/components/common/title/index.vue'
import { formatPrice, removeDotDash, isArrayEmpty } from '@/utils/utils'
import {
    INSTANT_BALL_MAX_PICK,
    INSTANT_BALL_PICK,
    INSTANT_BALL_RENDER,
    INSTANT_SLUG_API,
    INSTANT_SLUG_GAME,
    INSTANT_GAME_TITLE,
    INSTANT_DRAWS,
} from '@/constants/lotto'

export default {
    mixins: [languageMixins],
    // eslint-disable-next-line vue/no-unused-components
    components: { ButtonCommon, LoadingScreen, TitleCommon },
    data() {
        return {
            isLoadingPage: true,
            isEmpty: false,
            dataLottoInstant: {},
            listNumber: [],
            selection: [],
            valueSelection: '',
            wager: '',
            INSTANT_GAME_TITLE,
        }
    },
    mounted() {
        this.fetchData()
    },
    created() {
        EventBus.$on('on-clear-select-number', () => this.onClearSelectNumber())
    },
    watch: {
        selection() {
            this.renderValueNumber()
        },
    },
    destroyed() {
        EventBus.$off('on-clear-select-number')
    },
    methods: {
        async fetchData() {
            try {
                const resp = await gamesService.getListGames(INSTANT_SLUG_API)
                if (!isArrayEmpty(resp.data)) {
                    const dataInstant = resp.data[0]

                    this.dataLottoInstant = dataInstant
                    this.wager = this.renderPrice(parseFloat(dataInstant.game.price))
                    this.listNumber = Array.from(Array(INSTANT_BALL_RENDER), (_, i) => {
                        return { number: ++i, selected: false }
                    })
                } else {
                    this.isEmpty = true
                }
            } catch (error) {
                this.isEmpty = true
                console.log('🚀 ~ fetchData ~ error:', error)
            } finally {
                this.isLoadingPage = false
            }
        },

        /// action ball
        onClickQuickPick() {
            let numbers = Array(INSTANT_BALL_RENDER)
                .fill(0)
                .map((_, index) => index + 1)
            numbers.sort(() => Math.random() - 0.5)
            numbers = [...numbers.slice(0, INSTANT_BALL_PICK)]
            this.selection = [...numbers]
        },

        onSelectNumber(number) {
            const selection = this.selection
            const index = selection.findIndex((x) => x === number)
            if (index !== -1) {
                selection.splice(index, 1)
            } else {
                if (INSTANT_BALL_MAX_PICK > this.selection.length) {
                    selection.push(number)
                }
            }

            this.selection = [...selection]
        },

        removeDotDash(e) {
            this.valueSelection = removeDotDash(this.valueSelection)
        },

        renderTextTickets(arr) {
            let valueNumberSelectRender = ''
            arr.forEach((x, index) => {
                valueNumberSelectRender = `${valueNumberSelectRender}${index !== 0 ? ',' : ''} ${x}`
            })
            return valueNumberSelectRender
        },

        renderValueNumber() {
            const selection = [...this.selection]
            const numberSelect = selection.sort((a, b) => a - b)
            const valueNumberSelectClone = this.renderTextTickets(numberSelect)
            this.valueSelection = valueNumberSelectClone
        },

        onBlurValueSelection() {
            let inputArray = this.valueSelection
                .split(',')
                .map((num) => Number(num.trim()))
                .filter((num, index, self) => num >= 1 && num <= INSTANT_BALL_RENDER && index === self.indexOf(num))

            inputArray = inputArray.slice(0, INSTANT_BALL_MAX_PICK)
            this.valueSelection = inputArray.join(',')
            this.selection = [...inputArray]
        },

        // action screen

        onShowConfirmClear() {
            const param = {
                description: 'Are you sure you want to delete?',
                nameFunction: 'on-clear-select-number',
            }
            EventBus.$emit('on-show-dialog', param)
        },

        onClearSelectNumber() {
            this.selection = []
        },

        onAddToCart() {
            this.$store.state.isLoadingAddCart = true
            if (this.selection.length < INSTANT_BALL_MAX_PICK) {
                const param = {
                    text: this.getTranslatedText(`Please make sure that you select [number] of balls!`, {
                        number: INSTANT_BALL_MAX_PICK,
                    }),
                    type: 'warning',
                }
                EventBus.$emit('show-notications', param)
                this.$store.state.isLoadingAddCart = false
                return false
            }

            this.$store.state.isLoadingAddCart = false

            const { game, events } = this.dataLottoInstant
            const wager = parseFloat(this.wager.replaceAll(',', ''))

            const param = {
                id: new Date()
                    .getTime()
                    .toString(36)
                    .concat(performance.now().toString(), Math.random().toString())
                    .replace(/\./g, ''),
                game: game.slug,
                selection: [this.selection],
                price: wager,
                draws: INSTANT_DRAWS,
                gameType: INSTANT_SLUG_GAME,
                eventDate: events?.[0].draw_date || '',
            }

            this.$store.dispatch('onAddToCartLottoNotEvent', param)
            this.onClearSelectNumber()
        },

        // uti render
        onClickBack() {
            this.$router.push('/')
        },

        renderPrice(price, decimal = 0) {
            return formatPrice(price, decimal)
        },

        hideKeypad(ref) {
            this.$refs[ref].blur()
        },
    },
}
</script>

<style lang="scss">
@import '~ThemePath/scss/games/_number.scss';
</style>
