import { INSTANT_SLUG_GAME } from '@/constants/lotto';
import languageMixins from '@/mixins/language/language-mixins';
import { formatDateUTC00, isStrEmpty, formatPrice, formatFromUTCStringToFrCustomString, getSettingDevice, splitDataLottoInstant } from '@/utils/utils';

const LOGO_URL_LOTTO = process.env.VUE_APP_LOGO_LOTTO_URL;
const LOGO_URL_SPORT = process.env.VUE_APP_LOGO_SPORT_URL;

const LOGO_64_LOTTO = process.env.VUE_APP_LOGO_LOTTO_64;
const LOGO_64_SPORT = process.env.VUE_APP_LOGO_SPORT_64;

let logoKey, LOTTO_LOGO, SPORT_LOGO = '';

if (LOGO_64_LOTTO && LOGO_64_LOTTO !== '' && LOGO_64_SPORT && LOGO_64_SPORT !== '') {
    logoKey = 'logo64';
    LOTTO_LOGO = LOGO_64_LOTTO
    SPORT_LOGO = LOGO_64_SPORT
} else {
    logoKey = 'logo';
    LOTTO_LOGO = LOGO_URL_LOTTO
    SPORT_LOGO = LOGO_URL_SPORT
}

// const LOGO_PRINT = process.env.VUE_APP_LOGO_LOTTO_64;
// const LOGO_SPORT_PRINT = process.env.VUE_APP_LOGO_SPORT_64;

export default {
    mixins: [languageMixins],
    data() {
        return {};
    },
    methods: {
        printBodyLottoQuickLottery(param) {
            const body = []
            let winnings = 0
            let wager = 0
            for (let index = 0; index < param.bets.entries.length; index++) {
                const element = param.bets.entries[index]

                winnings += parseFloat(element.winnings)
                wager += parseFloat(element.entry.wager)

                const gameName = element.game.game.toUpperCase()

                const drawDateFormat = formatDateUTC00(new Date())

                const drawDate = `${drawDateFormat.day}/${drawDateFormat.month}/${drawDateFormat.year} ${drawDateFormat.hour}:${drawDateFormat.minutes}`

                const dataBet = element.entry.bet[0]
                const betNumberFirst = splitDataLottoInstant(1, dataBet).join(' ')
                const betNumberLast = splitDataLottoInstant(2, dataBet).join(' ')

                const itemBody = [
                    {
                        type: 'printTextCustom',
                        value: gameName,
                        fontSize: 30,
                        isUnderline: false,
                        alignment: 1,
                    },
                    {
                        type: 'printTextCustom',
                        value: this.getTranslatedText('Your numbers'),
                        fontSize: 28,
                        isUnderline: false,
                        alignment: 1,
                    },
                    {
                        type: 'printTextCustom',
                        value: betNumberFirst,
                        fontSize: 25,
                        isUnderline: false,
                        alignment: 1,
                    },
                    {
                        type: 'printTextCustom',
                        value: betNumberLast,
                        fontSize: 25,
                        isUnderline: false,
                        alignment: 1,
                    },
                    {
                        label: `${this.getTranslatedText('draw name')}:`,
                        value: element.event.drawName,
                        type: 'printTable',
                        column: [4, 2],
                        align: [0, 2],
                        isUnderline: false,
                    },
                    {
                        label: `${this.getTranslatedText('draw date')}:`,
                        value: `${drawDate.split(' ')[0]}`,
                        type: 'printTable',
                        column: [4, 3],
                        align: [0, 2],
                        isUnderline: false,
                    },
                    {
                        label: ``,
                        value: `${drawDate.split(' ')[1]}`,
                        type: 'printTable',
                        column: [4, 3],
                        align: [0, 2],
                        isUnderline: false,
                    },
                    {
                        type: 'printTextCustom',
                        value: this.getTranslatedText('draw number'),
                        fontSize: 28,
                        isUnderline: false,
                        alignment: 1,
                    },
                ]

                const result = !isStrEmpty(element.result) ? JSON.parse(element.result) : []
                for (let i = 0; i < result.length; i += 5) {
                    const columnResult = {
                        type: 'printTextCustom',
                        value: result.slice(i, i + 5).join(' '),
                        fontSize: 25,
                        isUnderline: false,
                        alignment: 1,
                    }
                    itemBody.push(columnResult)
                }

                const itemPrice = [
                    {
                        label: `${this.getTranslatedText('wager')}:`,
                        value: `${formatPrice(element.entry.wager)}`,
                        type: 'printTable',
                        column: [2, 4],
                        align: [0, 2],
                        isUnderline: false,
                    },
                ]

                itemBody.push(...itemPrice)

                if (param.bets.entries.length - 1 !== index) {
                    itemBody.push({
                        type: 'printEmptyLines',
                        line: 1,
                    })
                }

                body.push(...itemBody)
            }

            const itemPrice = [
                {
                    type: 'printDashLine',
                },
                {
                    label: `${this.getTranslatedText('total wager')}:`,
                    value: `${formatPrice(wager)}`,
                    type: 'printTable',
                    column: [3, 3],
                    align: [0, 2],
                    isUnderline: false,
                },
            ]

            body.push(...itemPrice)

            return body
        },

        printBodyLotto(param) {
            const body = []
            for (let index = 0; index < param.bets.entries.length; index++) {
                const element = param.bets.entries[index]

                let gameName = element.game.game.replace(/-/g, ' ').toUpperCase()
                gameName = gameName.replace('LOTTO GUINEE', 'LOTO FORTUNE')
                gameName = this.getTranslatedText(gameName.toLowerCase())
                gameName = gameName.toUpperCase()

                let jackpot = element.game.jackpot

                const drawDateFormat = formatDateUTC00(element.event.drawDate)

                const drawDate = `${drawDateFormat.day}/${drawDateFormat.month}/${drawDateFormat.year} ${drawDateFormat.hour}:${drawDateFormat.minutes}`

                let numberTicket = element.entry.bet[0].join(', ')
                if (element.entry.bet[1]) {
                    numberTicket = numberTicket + ' - ' + element.entry.bet[1].join(', ')
                }

                const itemBody = [
                    {
                        type: 'printTextCustom',
                        value: gameName,
                        fontSize: 30,
                        isUnderline: false,
                        alignment: 1,
                    },
                    {
                        type: 'printTextCustom',
                        value: numberTicket,
                        fontSize: 25,
                        isUnderline: false,
                        alignment: 1,
                    },
                    {
                        type: 'setFontSize',
                        size: 25,
                    },
                    {
                        label: `${this.getTranslatedText('wager')}:`,
                        value: `${formatPrice(element.entry.wager)}`,
                        type: 'printTable',
                        column: [2, 4],
                        align: [0, 2],
                        isUnderline: false,
                    },
                    {
                        label: `${this.getTranslatedText('draw name')}:`,
                        value: `${element.event.drawName}`,
                        type: 'printTable',
                        column: [3, 3],
                        align: [0, 2],
                        isUnderline: false,
                        columnName: 'draw name',
                    },
                    {
                        label: `${this.getTranslatedText('draw date')}:`,
                        value: `${drawDate.split(' ')[0]}`,
                        type: 'printTable',
                        column: [4, 3],
                        align: [0, 2],
                        isUnderline: false,
                    },
                    {
                        label: ``,
                        value: `${drawDate.split(' ')[1]}`,
                        type: 'printTable',
                        column: [4, 3],
                        align: [0, 2],
                        isUnderline: false,
                    },
                ]

                if (element.event.drawName.length > 14) {
                    const indexToReplace = itemBody.findIndex((item) => item?.columnName === 'draw name')

                    const newColumn = [
                        {
                            type: 'setAlignLeft',
                        },
                        {
                            label: `${this.getTranslatedText('draw name')}:`,
                            value: ``,
                            type: 'printTable',
                            column: [5, 1],
                            align: [0, 2],
                            isUnderline: false,
                        },
                        {
                            type: 'setAlignRight',
                        },
                        {
                            label: ``,
                            value: `${element.event.drawName}`,
                            type: 'printTable',
                            column: [1, 5],
                            align: [0, 2],
                            isUnderline: false,
                        },
                        {
                            type: 'setAlignCenter',
                        },
                    ]
                    itemBody.splice(indexToReplace, 1, ...newColumn)
                }

                if (jackpot) {
                    itemBody.push({
                        type: 'printTextCustom',
                        value: `Jackpot: ${formatPrice(parseFloat(jackpot))}`,
                        fontSize: 25,
                        isUnderline: false,
                        alignment: 1,
                    })
                }

                if (param.bets.entries.length - 1 !== index) {
                    itemBody.push({
                        type: 'printEmptyLines',
                        line: 1,
                    })
                }

                if (param.isCombinations && element.type && element.combinations) {
                    const itemComninations = [
                        {
                            label: `${this.getTranslatedText('type')}:`,
                            value: `${element.type}`,
                            type: 'printTable',
                            column: [2, 4],
                            align: [0, 2],
                            isBold: true,
                            isUnderline: false,
                        },
                        {
                            label: `${this.getTranslatedText('combinations')}:`,
                            value: `${element.combinations}`,
                            type: 'printTable',
                            column: [4, 2],
                            align: [0, 2],
                            isBold: true,
                            isUnderline: false,
                        },
                    ]

                    itemBody.splice(3, 0, ...itemComninations)
                }

                body.push(...itemBody)
            }

            const bodyFooter = [
                {
                    type: 'printDashLine',
                },
            ];

            body.push(...bodyFooter);

            return body
        },

        printLoto(param, isReprint = false) {
            const isCheckFormatQuickLottery = param.bets.entries.some(
                (bet) => bet.game.game == this.getTranslatedText(INSTANT_SLUG_GAME)
            )

            let font = this.isCheckFontMobile()

            let printerData = {};

            let ticketId = isCheckFormatQuickLottery ? param.transaction.external_id : param.transaction.transaction_id;

            const dateFormat = formatDateUTC00(new Date());

            let valueDate = `${dateFormat.day}/${dateFormat.month}/${dateFormat.year} ${dateFormat.hour}:${dateFormat.minutes}`;

            if (!isStrEmpty(ticketId)) {
                if (!ticketId.toString().startsWith('L-')) ticketId = 'L-' + ticketId;
            }

            printerData["init"] = [
                {
                    type: "setAlignCenter",
                },
            ]

            printerData[logoKey] = LOTTO_LOGO;

            printerData['header'] = [
                {
                    type: 'setAlignCenter',
                },
                {
                    type: 'printTextCustom',
                    value: valueDate,
                    fontSize: 25,
                    isUnderline: false,
                    alignment: 1,
                },
                {
                    type: 'printTextCustom',
                    value: ticketId,
                    fontSize: 25,
                    isUnderline: false,
                    alignment: 1,
                },
            ];

            if (param.raffle_print_data && param.raffle_print_data.length > 0) {
                printerData['header'] = printerData['header'].concat([
                    {
                        type: 'printTextCustom',
                        value: `${this.getTranslatedText('raffle entry code')}`,
                        fontSize: 25,
                        isUnderline: false,
                        alignment: 1,
                    },
                    {
                        type: 'printTextCustom',
                        value: param.raffle_code,
                        fontSize: 25,
                        isUnderline: false,
                        alignment: 1,
                    },
                ]);
            }

            printerData['header'] = printerData['header'].concat([
                {
                    type: 'printDashLine',
                },
                {
                    type: 'printEmptyLines',
                    line: 1,
                },
            ]);

            printerData['titleBody'] = [
                {
                    type: 'setAlignCenter',
                },
                {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('guinee lottery')}`,
                    fontSize: 35,
                    isBold: true,
                    isUnderline: false,
                    alignment: 1,
                    fontSizeMobi: 1, // 0 ~ 5
                },
                {
                    type: 'printEmptyLines',
                    line: 1,
                },
            ];

            if (isReprint) {
                let item = {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('reprint')}`,
                    fontSize: 35,
                    isBold: true,
                    isUnderline: false,
                    alignment: 1,
                    fontSizeMobi: 1, // 0 ~ 5
                };
                printerData['titleBody'].splice(1, 0, item);
            }

            const body = isCheckFormatQuickLottery ? this.printBodyLottoQuickLottery(param) : this.printBodyLotto(param)

            printerData['footer'] = [
                {
                    type: 'setFontSize',
                    size: 30,
                }
            ]

            if(!isCheckFormatQuickLottery) {
                printerData['footer'].push( {
                    label: `${this.getTranslatedText('total')}:`,
                    value: `${formatPrice(param.transaction.total, 2)} ${param.transaction.currency}`,
                    type: 'printTable',
                    column: [2, 4],
                    align: [0, 2],
                    isBold: true,
                    isUnderline: false,
                })
            }

            if (param.raffle_print_data && param.raffle_print_data.length > 0) {
                printerData['footer'].push({
                    type: 'printDashLine',
                })
                printerData['footer'].push({
                    type: 'printEmptyLines',
                    line: 1,
                })

                printerData['footer'] = printerData['footer'].concat([
                    {
                        type: 'printTextCustom',
                        value: `${this.getTranslatedText('raffle entry code')}`,
                        fontSize: 25,
                        isUnderline: false,
                        alignment: 1,
                    },
                    {
                        type: 'printTextCustom',
                        value: param.raffle_code,
                        fontSize: 25,
                        isUnderline: false,
                        alignment: 1,
                    },
                ]);

                param.raffle_print_data.forEach(e => {
                    let str = this.getTranslatedText(e.text)
                    if (e.replaces && e.replaces.length > 0) {
                        // format string
                        e.replaces.forEach(s => {
                            str = str.replace('%s', s)
                        })
                    }

                    printerData['footer'].push({
                        type: 'printTextCustom',
                        value: str,
                        fontSize: 25,
                        isUnderline: false,
                        alignment: 1,
                    })
                });
                printerData['footer'].push({
                    type: 'printDashLine',
                })
            }

            if (isReprint) {
                printerData['footer'].push({
                    type: 'printEmptyLines',
                    line: 1,
                })
                let item = {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('reprint')}`,
                    fontSize: 35,
                    isBold: true,
                    isUnderline: false,
                    alignment: 1,
                    fontSizeMobi: 1, // 0 ~ 5
                };
                printerData['footer'].push(item);
            }

            printerData['footer'] = printerData['footer'].concat([
                {
                    type: 'printEmptyLines',
                    line: 1,
                },
                {
                    type: 'barcode',
                    value: ticketId,
                    isShowCode: true,
                    fontSize: 25,
                    height: 80
                },
                {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('Gains valides pendant 7 jours')}`,
                    fontSize: 20,
                    isUnderline: false,
                    alignment: 1,
                    font: font // make it smaller on mobi only
                },
                {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('Fortune aux parieurs et')}`,
                    fontSize: 20,
                    isUnderline: false,
                    alignment: 1,
                    font: font // make it smaller on mobi only
                },
                {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('Bénéfice à la Nation')}`,
                    fontSize: 20,
                    isUnderline: false,
                    alignment: 1,
                    font: font // make it smaller on mobi only
                },
                {
                    type: 'printEmptyLines',
                    line: 1,
                },
                {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('end')}`,
                    fontSize: 24,
                    isUnderline: false,
                    alignment: 1,
                },
                {
                    type: 'cutPaper',
                },
            ]);

            printerData['body'] = body;
            
            parent.postMessage({ command: 'print-by-format', data: printerData }, '*');
        },

        printSports(printParam, isReprint = false) {
            const betStorage = JSON.parse(printParam);
            const voucher = betStorage[0].voucher;
            const transactions = betStorage[0].transactions;
            let printerData = {};

            printerData["init"] = [
                {
                    type: "setAlignCenter",
                },
            ]

            printerData[logoKey] = SPORT_LOGO;

            printerData['title'] = [
                {
                    type: 'setAlignCenter',
                },
                {
                    type: 'printEmptyLines',
                    line: 1,
                },
                {
                    type: 'printTextCustom',
                    value: this.getTranslatedText('SPORTS TICKET'),
                    fontSize: 35,
                    fontSizeMobi: 1, // 0 ~ 5
                    isBold: true,
                    isUnderline: false,
                    alignment: 1,
                },
                {
                    type: 'printEmptyLines',
                    line: 1,
                },
            ];

            if (isReprint) {
                let item = {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('reprint')}`,
                    fontSize: 35,
                    isBold: true,
                    isUnderline: false,
                    alignment: 1,
                    fontSizeMobi: 1, // 0 ~ 5
                };
                printerData['title'].splice(3, 0, item);
            }

            for (const transaction of transactions) {
                const fontSize = 25;

                let winningAfterTax = formatPrice(parseFloat(transaction.winning_after_tax));
                let totalOdds = formatPrice(parseFloat(transaction.odds_total));
                let wager = formatPrice(parseFloat(transaction.stake));
                const bets = transaction.bets;
                printerData['header'] = [
                    {
                        type: 'setAlignLeft',
                    },
                    {
                        type: 'printTextCustom',
                        value: `${this.getTranslatedText('Date')}: ${voucher.created_at.split('.')[0]}`,
                        fontSize,
                        isBold: false,
                        isUnderline: false,
                    },
                    {
                        type: 'printTextCustom',
                        value: `${this.getTranslatedText('betslip id')}: ${transaction.external_bet_id}`,
                        fontSize,
                        isBold: false,
                        isUnderline: false,
                    },
                    {
                        type: 'printEmptyLines',
                        line: 1,
                    },
                ];

                let typeBeslip = '';

                if (transaction.bets.length > 1) {
                    typeBeslip = `---${this.getTranslatedText('Multiple')} : (${transaction.bets.length}) ${this.getTranslatedText('lines').toString().toLowerCase()}---`;
                } else {
                    typeBeslip = `------ ${this.getTranslatedText('Simple')} ------`;
                }

                printerData['titleBody'] = [
                    {
                        type: 'setAlignCenter',
                    },
                    {
                        type: 'printTextCustom',
                        value: typeBeslip,
                        fontSize: 25,
                        alignment: 1,
                    },
                    {
                        type: 'setAlignLeft',
                    },
                ];
                let body = [];
                for (var b = 0; b < bets.length; b++) {
                    let item = [
                        {
                            type: 'printTextCustom',
                            value: `${transaction.bets.length > 1 ? '[' + (b + 1) + '] ' : ''}${transaction.bets[b].tournament_name}`,
                            fontSize: 22,
                            isUnderline: false,
                        },
                        {
                            type: 'printTextCustom',
                            value: `${bets[b].match_name_no_date || bets[b].match_name}`,
                            fontSize: 22,
                            isBold: false,
                            isUnderline: false,
                        },
                        {
                            type: 'printTextCustom',
                            value: `${bets[b].draw_time}`,
                            fontSize: 22,
                            isBold: false,
                            isUnderline: false,
                        },
                        {
                            type: 'printTextCustom',
                            value: `${bets[b].market_name}: ${bets[b].outcome_name} @ ${bets[b].odd}`,
                            fontSize: 22,
                            isBold: false,
                            isUnderline: false,
                        },
                    ];
                    if (b <= bets.length - 2) {
                        let items = {
                            type: 'printCutDashLine',
                        };
                        item.push(items);
                    }
                    body.push(...item);
                }
                const footer = [
                    {
                        type: 'printEmptyLines',
                        line: 1,
                    },
                    {
                        type: 'printTextCustom',
                        value: `${this.getTranslatedText('Total odds')}: ${totalOdds}`,
                        fontSize,
                        isBold: false,
                        isUnderline: false,
                    },
                    {
                        type: 'printTextCustom',
                        value: `${this.getTranslatedText('Wager')}: ${wager}`,
                        fontSize,
                        isBold: false,
                        isUnderline: false,
                    },
                    {
                        type: 'printTextCustom',
                        value: `${this.getTranslatedText('possible winnings')}: ${winningAfterTax}`,
                        fontSize,
                        isBold: false,
                        isUnderline: false,
                    },
                    {
                        type: 'setAlignCenter',
                    },
                    {
                        type: 'printEmptyLines',
                        line: 1,
                    },
                    {
                        type: 'barcode',
                        value: transaction.external_bet_id.startsWith('S-') ? transaction.external_bet_id : `S-${transaction.external_bet_id}`,
                        isShowCode: true,
                        codePosition: '',
                        fontSize: 25,
                        height: 80
                    },
                    {
                        type: 'printEmptyLines',
                        line: 1,
                    },
                    {
                        type: 'printTextCustom',
                        value: `${this.getTranslatedText('end')}`,
                        fontSize: 24,
                        isUnderline: false,
                        alignment: 1,
                    },
                    {
                        type: 'cutPaper',
                        value: ''
                    },
                ];

                if (isReprint) {
                    let item = {
                        type: 'printTextCustom',
                        value: `${this.getTranslatedText('reprint')}`,
                        fontSize: 35,
                        isBold: true,
                        isUnderline: false,
                        alignment: 1,
                        fontSizeMobi: 1, // 0 ~ 5
                    };
                    footer.splice(6, 0, item);
                }

                printerData['body'] = body;
                printerData['footer'] = footer;
            }

            parent.postMessage(
                {
                    command: 'print-by-format',
                    data: printerData,
                },
                '*'
            );
        },

        printSettle(printParam) {
            let printerData = {};

            let date = formatDateUTC00(new Date());
            let datePrint = `${date.day}/${date.month}/${date.year} ${date.hour}:${date.minutes}`;

            printerData[logoKey] = LOTTO_LOGO;

            printerData['body'] = [
                {
                    type: 'setAlignCenter',
                },
                {
                    type: 'printEmptyLines',
                    line: 1,
                },
                {
                    type: 'printTextCustom',
                    value: this.getTranslatedText('receipt'),
                    fontSize: 30,
                    isBold: true,
                    isUnderline: false,
                    alignment: 1,
                },
                {
                    type: 'printEmptyLines',
                    line: 1,
                },
                {
                    type: 'setAlignLeft',
                },
                {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('date')}: ${datePrint}`,
                    alignment: 0,
                },
                {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('branch')}: ${printParam.branch_name}`,
                    alignment: 0,
                },
                {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('cashier')}: ${printParam.cashier_name}`,
                    alignment: 0,
                },
                {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('code')}: ${printParam.code}`,
                    alignment: 0,
                },
                {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('settlement')}: ${printParam.currency_code + ' ' + formatPrice(parseFloat(printParam.winnings))}`,
                    alignment: 0,
                },
                {
                    type: 'printEmptyLines',
                    line: 1,
                },
                {
                    type: 'setAlignCenter',
                },
                {
                    type: 'barcode',
                    value: printParam.code,
                    isShowCode: true,
                    height: 80,
                    fontSize: 25
                },
                {
                    type: 'cutPaper',
                },
            ];

            parent.postMessage(
                {
                    command: 'print-by-format',
                    data: printerData,
                },
                '*'
            );
        },

        printReport(printParam) {
            let printerData = {};

            let currentDate = formatDateUTC00(new Date());
            let printDate = `${currentDate.day}/${currentDate.month}/${currentDate.year} ${currentDate.hour}:${currentDate.minutes}`;

            let today = `${currentDate.year}-${currentDate.month}-${currentDate.day}`;
            let trueBalance = printParam.from === printParam.to && printParam.from === today ? printParam.sum.true_balance : printParam.sum.cash_difference;
            let reports = printParam.report.filter((item) => item['product_type']);
            let reportParams = [];

            // to do report
            reports.forEach((r) => {
                let item = [
                    {
                        type: 'setAlignCenter',
                    },
                    {
                        type: 'printEmptyLines',
                        line: 1,
                    },
                    {
                        type: 'printTextCustom',
                        value: `${r.product_type}`,
                        fontSize: 24,
                        isBold: true,
                        isUnderline: false,
                        alignment: 1,
                    },
                    {
                        type: 'setAlignLeft',
                    },
                    {
                        type: 'printTextCustom',
                        value: `${this.getTranslatedText('wager')}: ${r.currency} ${formatPrice(parseFloat(r.wagers))}`,
                        fontSize: 24,
                        isBold: false,
                        isUnderline: false,
                    },
                    {
                        type: 'printTextCustom',
                        value: `${this.getTranslatedText('payouts')}: ${r.currency} ${formatPrice(parseFloat(r.payouts))}`,
                        fontSize: 24,
                        isBold: false,
                        isUnderline: false,
                    },
                ];

                reportParams.push(...item);
            });

            printerData[logoKey] = LOTTO_LOGO;

            printerData['body'] = [
                {
                    type: 'setAlignCenter',
                },
                {
                    type: 'printEmptyLines',
                    line: 1,
                },
                {
                    type: 'printTextCustom',
                    value: printParam.isFinal ? this.getTranslatedText('final report of') : this.getTranslatedText('report of'),
                    fontSize: 30,
                    isBold: true,
                    isUnderline: false,
                    alignment: 1,
                },
                {
                    type: 'printTextCustom',
                    value: `${printParam.cashier.first_name} ${printParam.cashier.last_name}`,
                    fontSize: 30,
                    isBold: true,
                    isUnderline: false,
                    alignment: 1,
                },
                {
                    type: 'printEmptyLines',
                    line: 1,
                },
                {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('report date')}`,
                    fontSize: 26,
                    isBold: false,
                    isUnderline: false,
                    alignment: 1,
                },
                {
                    type: 'printTextCustom',
                    value: `${printParam.date}`,
                    fontSize: 26,
                    isBold: false,
                    isUnderline: false,
                    alignment: 1,
                },
                {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('print date')}`,
                    fontSize: 26,
                    isBold: false,
                    isUnderline: false,
                    alignment: 1,
                },
                {
                    type: 'printTextCustom',
                    value: `${printDate}`,
                    fontSize: 26,
                    isBold: false,
                    isUnderline: false,
                    alignment: 1,
                },
                {
                    type: 'printEmptyLines',
                    line: 1,
                },

                {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('summary')}`,
                    fontSize: 26,
                    isBold: true,
                    isUnderline: false,
                    alignment: 1,
                },
                {
                    type: 'setAlignLeft',
                },
                {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('cash deposits')}: ${printParam.sum.currency} ${formatPrice(parseFloat(printParam.sum.cash_deposits))}`,
                    fontSize: 26,
                    isBold: false,
                    isUnderline: false,
                },
                {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('cancellations')}: ${printParam.sum.currency} ${formatPrice(parseFloat(printParam.sum.refunded_cancelations))}`,
                    fontSize: 26,
                    isBold: false,
                    isUnderline: false,
                },
                {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('payouts')}: ${printParam.sum.currency} ${formatPrice(parseFloat(printParam.sum.payouts))}`,
                    fontSize: 26,
                    isBold: false,
                    isUnderline: false,
                },
                {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('true balance')}: ${printParam.sum.currency} ${formatPrice(parseFloat(trueBalance))}`,
                    fontSize: 26,
                    isBold: true,
                    isUnderline: false,
                },
            ];

            printerData['body'].push(...reportParams);
            printerData['footer'] = [
                {
                    type: 'cutPaper',
                    value: ''
                },
            ];

            parent.postMessage(
                {
                    command: 'print-by-format',
                    data: printerData,
                },
                '*'
            );
        },

        printVoucher(printParam) {
            const datePrint = this.renderDatePrint(new Date(), true);
            const isPayment = printParam.isPayment;
            const isCancel = printParam.isCancel;

            const titleVoucher = isPayment ? 'lonaguiweb paiment voucher' : isCancel ? 'annulation voucher' : 'lonaguiweb voucher';

            let printerData = {};

            printerData[logoKey] = LOTTO_LOGO;

            printerData['body'] = [
                {
                    type: 'printEmptyLines',
                    line: 1,
                },
                {
                    type: 'setAlignCenter',
                },
                {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText(titleVoucher)}`,
                    fontSize: 24,
                    isBold: true,
                    isUnderline: false,
                    alignment: 1,
                },
                {
                    label: `${this.getTranslatedText('date')}:`,
                    value: `${datePrint}`,
                    type: 'printTable',
                    column: [2, 4],
                    align: [0, 2],
                    isBold: true,
                    isUnderline: false,
                },
                {
                    label: `${this.getTranslatedText('amount')}:`,
                    value: `${printParam.amount} ${printParam.currency}`,
                    type: 'printTable',
                    column: [2, 4],
                    align: [0, 2],
                    isBold: true,
                    isUnderline: false,
                },
                {
                    type: 'printDashLine',
                },
                {
                    type: 'printEmptyLines',
                    line: 1,
                },
                {
                    type: 'printDashLine',
                },
                {
                    type: 'printEmptyLines',
                    line: 1,
                },
                {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText('lonaguiweb')}`,
                    fontSize: 24,
                    isBold: true,
                    isUnderline: false,
                    alignment: 1,
                },
                {
                    type: 'cutPaper',
                },
            ];

            if (isCancel) {
                let item = {
                    type: 'printTextCustom',
                    value: `${this.getTranslatedText(printParam.voucherCode)}`,
                    fontSize: 24,
                    isBold: true,
                    isUnderline: false,
                    alignment: 1,
                };

                printerData['body'].splice(7, 0, item);
            }

            if (!isCancel && !isPayment) {
                const expirationdate = this.convertDateString(printParam.expirationdate);

                let item = {
                    label: `${this.getTranslatedText('expiry date')}:`,
                    value: `${expirationdate}`,
                    type: 'printTable',
                    column: [4, 3],
                    align: [0, 2],
                    isBold: true,
                    isUnderline: false,
                };

                printerData['body'].splice(5, 0, item);
            }

            if (!isCancel) {
                let item = {
                    type: 'barcode',
                    value: printParam.voucherCode,
                    isShowCode: true,
                    fontSize: 25,
                    height: 80,
                    codePosition: 'above'
                };

                const locationItem = !isCancel && !isPayment ? 8 : 7;

                printerData['body'].splice(locationItem, 0, item);
            }

            parent.postMessage(
                {
                    command: 'print-by-format',
                    data: printerData,
                },
                '*'
            );
        },

        printTest() {
            let font = this.isCheckFontMobile()
            const printerData = {
                "init": [
                    {
                        "type": "setAlignCenter",
                    }],
                "logo": LOGO_URL_LOTTO,
                "header": [
                    {
                        "type": "setAlignCenter",
                    },
                    {
                        "type": "printTextCustom",
                        "value": "27/10/2023 11:19",
                        "fontSize": 25,
                        "isUnderline": false,
                        "alignment": 1
                    },
                    {
                        "type": "printTextCustom",
                        "value": "L-FAKETICKET",
                        "fontSize": 25,
                        "isUnderline": false,
                        "alignment": 1
                    },
                    {
                        "type": "printDashLine"
                    },
                    {
                        "type": "printEmptyLines",
                        "line": 1
                    }
                ],
                "titleBody": [
                    {
                        "type": "setAlignCenter",
                    },
                    {
                        "type": "printTextCustom",
                        "value": "LONAGUI",
                        "fontSize": 35,
                        "isBold": true,
                        "isUnderline": false,
                        "alignment": 1,
                        "fontSizeMobi": 1
                    },
                    {
                        "type": "printEmptyLines",
                        "line": 1
                    }
                ],
                "body": [
                    {
                        "type": "printTextCustom",
                        "value": "LOTO FORTUNE 5 90 TURBO3",
                        "fontSize": 30,
                        "isUnderline": false,
                        "alignment": 1
                    },
                    {
                        "type": "printTextCustom",
                        "value": "71, 80",
                        "fontSize": 25,
                        "isUnderline": false,
                        "alignment": 1
                    },
                    {
                        "type": "setFontSize",
                        "size": 25
                    },
                    {
                        "label": "Mise:",
                        "value": "2,000.00",
                        "type": "printTable",
                        "column": [
                            2,
                            4
                        ],
                        "align": [
                            0,
                            2
                        ],
                        "isUnderline": false
                    },
                    {
                        "label": "Nom du tirage:",
                        "value": "YIGUI",
                        "type": "printTable",
                        "column": [
                            3,
                            3
                        ],
                        "align": [
                            0,
                            2
                        ],
                        "isUnderline": false
                    },
                    {
                        "label": "Date du tirage:",
                        "value": "28/10/2023",
                        "type": "printTable",
                        "column": [
                            4,
                            3
                        ],
                        "align": [
                            0,
                            2
                        ],
                        "isUnderline": false
                    },
                    {
                        "label": "",
                        "value": "09:00",
                        "type": "printTable",
                        "column": [
                            4,
                            3
                        ],
                        "align": [
                            0,
                            2
                        ],
                        "isUnderline": false
                    },
                    {
                        "type": "printEmptyLines",
                        "line": 1
                    },
                    {
                        "type": "printTextCustom",
                        "value": "LOTO FORTUNE 5 90 TURBO3",
                        "fontSize": 30,
                        "isUnderline": false,
                        "alignment": 1
                    },
                    {
                        "type": "printTextCustom",
                        "value": "51, 60",
                        "fontSize": 25,
                        "isUnderline": false,
                        "alignment": 1
                    },
                    {
                        "type": "setFontSize",
                        "size": 25
                    },
                    {
                        "label": "Mise:",
                        "value": "2,000.00",
                        "type": "printTable",
                        "column": [
                            2,
                            4
                        ],
                        "align": [
                            0,
                            2
                        ],
                        "isUnderline": false
                    },
                    {
                        "label": "Nom du tirage:",
                        "value": "YIGUI",
                        "type": "printTable",
                        "column": [
                            3,
                            3
                        ],
                        "align": [
                            0,
                            2
                        ],
                        "isUnderline": false
                    },
                    {
                        "label": "Date du tirage:",
                        "value": "28/10/2023",
                        "type": "printTable",
                        "column": [
                            4,
                            3
                        ],
                        "align": [
                            0,
                            2
                        ],
                        "isUnderline": false
                    },
                    {
                        "label": "",
                        "value": "09:00",
                        "type": "printTable",
                        "column": [
                            4,
                            3
                        ],
                        "align": [
                            0,
                            2
                        ],
                        "isUnderline": false
                    },
                    {
                        "type": "printDashLine"
                    }
                ],
                "footer": [
                    {
                        "type": "setFontSize",
                        "size": 30
                    },
                    {
                        "label": "Total:",
                        "value": "4,000.00 GNF",
                        "type": "printTable",
                        "column": [
                            2,
                            4
                        ],
                        "align": [
                            0,
                            2
                        ],
                        "isBold": true,
                        "isUnderline": false
                    },
                    {
                        "type": "printEmptyLines",
                        "line": 1
                    },
                    {
                        "type": "barcode",
                        "value": "L-FAKETICKET",
                        "isShowCode": true,
                        "fontSize": 25,
                        "height": 80
                    },
                    {
                        "type": "printTextCustom",
                        "value": "Gains valides pendant 7 jours",
                        "fontSize": 20,
                        "isUnderline": false,
                        "alignment": 1,
                        "font": font
                    },
                    {
                        "type": "printTextCustom",
                        "value": "Fortune aux parieurs et",
                        "fontSize": 20,
                        "isUnderline": false,
                        "alignment": 1,
                        "font": font
                    },
                    {
                        "type": "printTextCustom",
                        "value": "Bénéfice à la nation",
                        "fontSize": 20,
                        "isUnderline": false,
                        "alignment": 1,
                        "font": font
                    },
                    {
                        "type": "printEmptyLines",
                        "line": 1
                    },
                    {
                        "type": "printTextCustom",
                        "value": "FIN",
                        "fontSize": 24,
                        "isUnderline": false,
                        "alignment": 1
                    },
                    {
                        "type": "cutPaper"
                    }
                ],
            }

            parent.postMessage(
                {
                    command: 'print-by-format',
                    data: printerData,
                },
                '*'
            );
        },

        printResult(data) {
            let printerData = {};

            printerData[logoKey] = LOTTO_LOGO;

            printerData['body'] = [
                {
                    type: 'setAlignCenter',
                },
                {
                    type: 'printEmptyLines',
                    line: 1,
                },
                {
                    type: 'printTextCustom',
                    value: this.getTranslatedText('result'),
                    fontSize: 30,
                    isBold: true,
                    alignment: 1,
                },
                {
                    type: 'printEmptyLines',
                    line: 1,
                },
                {
                    type: 'printDashLine',
                },
            ];

            for (let i = 0; i < data.length; i++) {
                let result = "";
                if (data[i].results && data[i].results[0]) {
                    result += data[i].results[0];
                }
                if (data[i].results && data[i].results[1]) {
                    result += "," + data[i].results[1];
                }
                // format("DD MMM YYYY HH:mm")}`
                let date = formatFromUTCStringToFrCustomString(data[i].draw_date);

                let bodies = [
                    {
                        type: "setAlignRight",
                    },
                    {
                        type: "printTextCustom",
                        value: date,
                        alignment: 2,
                    },
                    {
                        type: "setAlignLeft",
                    },
                    {
                        type: "printTextCustom",
                        value: `${data[i].draw_name}`,
                        alignment: 0,
                    },
                    {
                        type: "setAlignRight",
                    },
                    {
                        type: "printTextCustom",
                        value: `${result.replace(/,/g, ', ')}`,
                        alignment: 2,
                    },
                    {
                        type: "printDashLine",
                    },
                ];

                printerData["body"] = [...printerData["body"], ...bodies];
            }

            printerData["body"] = [
                ...printerData["body"],
                ...[
                    {
                        type: "cutPaper",
                    },
                ],
            ];

            parent.postMessage(
                {
                    command: 'print-by-format',
                    data: printerData,
                },
                '*'
            );
        },

        renderDatePrint(date, isNotShowSeconds = false, isNotShowTime = false) {
            const dateFormat = formatDateUTC00(date);
            let valueDate = `${dateFormat.day}-${dateFormat.month}-${dateFormat.year} ${dateFormat.hour}:${dateFormat.minutes}:${dateFormat.seconds}`;

            if (isNotShowSeconds) {
                valueDate = `${dateFormat.day}-${dateFormat.month}-${dateFormat.year} ${dateFormat.hour}:${dateFormat.minutes}`;
            }

            if (isNotShowTime) {
                valueDate = `${dateFormat.day}-${dateFormat.month}-${dateFormat.year}`;
            }

            return valueDate;
        },

        convertDateString(input) {
            const year = input.substr(0, 4);
            const month = input.substr(4, 2);
            const day = input.substr(6, 2);

            const output = `${day}/${month}/${year}`;
            return output;
        },

        isMobiDevice() {
            let device = getSettingDevice()
            if (!isStrEmpty(device)) {
                device = JSON.parse(device)
            }
            if (device?.serial?.startsWith('tb8765ap1_64_bsp')) {
                return true;
            }
            return false;
        },

        isCheckFontMobile() {
           return this.isMobiDevice() ? "1" : ""
        },
    },
};
